import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Button, theme } from '@damen/ui'

import { DESKTOP_DASHBOARD_GAP } from '@src/constants/Viewports'
import { LOADER } from '@src/constants/zIndexes'
import Link from 'next/link'

export const LoadingWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${LOADER};
`

export const Wrapper = styled.div`
	width: 100%;
	display: inline-flex;
	position: relative;
	overflow: hidden;

	@media ${theme.legacyMediaQueries.sm} {
		position: absolute;
	}

	@media ${theme.legacyMediaQueries.md} {
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`

export const ImageWrapper = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 0;

	@media ${theme.legacyMediaQueries.sm} {
		top: -80px;
	}

	@media ${theme.legacyMediaQueries.md} {
		top: 0;
	}
`

export const Image = styled(motion.img)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
`

export const Background = styled(motion.div)`
	background-color: ${theme.colors.white};
	display: block;
	width: 100%;
	position: relative;
	z-index: 1;
	padding: 80px 24px;
	overflow-y: auto;

	@media ${theme.legacyMediaQueries.md} {
		position: fixed;
		width: calc(100% - ${DESKTOP_DASHBOARD_GAP}px);
		right: 0;
		top: 0;
		bottom: 0;
		padding: 80px 64px;
	}
`

export const Name = styled.div`
	font-size: 42px;
	font-weight: bold;

	@media ${theme.legacyMediaQueries.md} {
		font-size: 48px;
	}
`

export const DashboardCard = styled.div`
	position: relative;
	border: 1px solid ${theme.colors.greyAccentLight};
	padding: 24px;
`

export const TotalCount = styled.span`
	margin-left: 8px;
	padding: 6px 12px;
	background: ${theme.colors.greyAccentLight};
	font-size: 14px;
	font-weight: normal;
`

export const ServiceRequestStatsTitle = styled.h3`
	margin-bottom: 16px;
	font-size: 24px;
`

export const DashboardRow = styled.div`
	margin-top: 24px;
	display: grid;
	gap: 24px;

	@media ${theme.legacyMediaQueries.lg} {
		grid-template-columns: 1fr 1fr;
	}
`

export const ServiceRequestsRowFullWidth = styled.div`
	margin-top: 24px;
	display: grid;
	gap: 24px;
`

export const StyledSeeAllButton = styled(Button.Default)`
	margin-top: 24px !important;
`

export const ListLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${theme.layout.spacingSM} 0;
	color: ${theme.colors.marineBlack};
	text-decoration: none;

	svg {
		transform: rotate(-90deg);
	}

	&:hover,
	&:focus {
		color: ${theme.colors.blue};
		text-decoration: underline;
		text-underline-offset: 4px;
	}
`

export const ListItem = styled.li`
	font-size: ${theme.typography.fontSizeText};
	line-height: 1.88;

	&:not(:last-of-type) {
		border-bottom: 1px solid ${theme.colors.blueIce};
	}
`

export const CardTitle = styled.h3`
	margin-bottom: ${theme.layout.spacingM};
`

export const CardContent = styled.div`
	margin-bottom: ${theme.layout.spacingM};
`

export const ConnectedAppsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${theme.layout.spacingM};
	margin-top: ${theme.layout.spacingL};
`
