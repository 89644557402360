import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'

import { useGetKnowledgeBaseQuery } from '@graphql/graphql'

import { useTranslationContext } from '@contexts/TranslationContext'

import {
	KNOWLEDGEBASE_TECHNICALPAPERS,
	KNOWLEDGEBASE_WEBINARS,
	KNOWLEDGEBASE_MANUALSANDINSTRUCTIONS
} from '@src/constants/Routes'
import { useLocale } from '@hooks/useLocale'

import KnowledgeBaseSkeleton from '@skeletons/KnowledgeBase'
import { KnowledgeBaseOverviewStoryblok } from '@graphql/storyblokcomponents'

import ErrorState from '@components/UI/Error'
import VideoModal from '@components/Modals/VideoModal'
import TechnicalPapersItemComponent from '@components/TechnicalPapersItem'
import WebinarItemComponent from '@components/WebinarItem'
import KnowledgeBaseItem from '@components/KnowledgeBaseItem'
import CardSummary from './CardSummary'

import {
	Wrapper,
	CardSummaryContainer,
	CardSummaryGridContainer
} from './styles'
import { Card } from './CardSummary/styles'

const KnowledgeBaseOverview = ({ blok }) => {
	const {
		labelLatest,
		labelMin,
		labelTechnicalPapersButton,
		labelWebinarsButton,
		technicalPapersDescription,
		technicalPapersTitle,
		webinarsDescription,
		webinarsTitle,
		errorDescription,
		manualsAndInstructionsTitle,
		manualsAndInstructionsDescription,
		manualsAndInstructionsButton
	} = blok as KnowledgeBaseOverviewStoryblok
	const { locale } = useLocale()
	const router = useRouter()
	const { translations } = useTranslationContext()

	const [isOpen, setIsOpen] = useState(false)
	const [{ videoId, title }, setVideo] = useState({
		videoId: null,
		title: null
	})

	// API call
	const { data, loading, error } = useGetKnowledgeBaseQuery({
		fetchPolicy: 'no-cache',
		variables: {
			language: locale
		}
	})

	// Event handlers
	const allTechnicalPapersClickHandler = useCallback(() => {
		router.push(KNOWLEDGEBASE_TECHNICALPAPERS)
	}, [router])

	const allWebinarsClickHandler = useCallback(() => {
		router.push(KNOWLEDGEBASE_WEBINARS)
	}, [router])

	const allManualsAndInstructionsClickHandler = useCallback(() => {
		router.push(KNOWLEDGEBASE_MANUALSANDINSTRUCTIONS)
	}, [router])

	const webinarItemClickHandler = useCallback(
		// Order is needed for onClick to work
		// eslint-disable-next-line @typescript-eslint/default-param-last, default-param-last
		(id: number = null, heading: string) => {
			if (id) {
				setVideo({ videoId: id, title: heading })
				setIsOpen(true)
			}
		},
		[setIsOpen, setVideo]
	)

	const videoModalCloseHandler = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])

	if (loading) {
		return <KnowledgeBaseSkeleton />
	}

	if (error) {
		return (
			<Wrapper>
				<Card>
					<ErrorState content={errorDescription} showBowWave />
				</Card>
			</Wrapper>
		)
	}

	const webinars = data.webinars.items
	const technicalPapers = data.technicalPapers.items
	const manualsAndInstructions = data.manualsAndInstructions.items

	const webinarItems = webinars
	const WebinarItem = WebinarItemComponent

	const technicalPapersItems = technicalPapers
	const TechnicalPapersItem = TechnicalPapersItemComponent

	const manualsAndInstructionsItems = manualsAndInstructions

	const { length: technicalPapersLength } = technicalPapers
	const { length: webinarsLength } = webinars
	const { length: manualsAndInstructionsLength } = manualsAndInstructions

	return (
		<>
			<Wrapper>
				<CardSummary
					title={manualsAndInstructionsTitle}
					description={manualsAndInstructionsDescription}
					buttonLabel={manualsAndInstructionsButton}
					onButtonClick={allManualsAndInstructionsClickHandler}
					labelLatest={labelLatest}
				>
					<CardSummaryGridContainer>
						{manualsAndInstructionsItems.map((item, index) => {
							const key = `${item.title}-${index}`
							if (
								item.__typename === 'KnowledgeBaseDownloadItem'
							) {
								return (
									<KnowledgeBaseItem.KnowledgeBaseDownloadItem
										id={key}
										key={key}
										isLast={
											index ===
											manualsAndInstructionsLength - 1
										}
										hasBorder={false}
										buttonLabel={
											translations?.downloadPDFLabel
										}
										{...item}
									/>
								)
							}
							return (
								<KnowledgeBaseItem.KnowledgeBaseVideoItem
									id={key}
									key={key}
									durationUnit={labelMin}
									onClick={webinarItemClickHandler}
									isLast={
										index ===
										manualsAndInstructionsLength - 1
									}
									hasBorder={false}
									{...item}
								/>
							)
						})}
					</CardSummaryGridContainer>
				</CardSummary>
				<CardSummary
					title={technicalPapersTitle}
					description={technicalPapersDescription}
					buttonLabel={labelTechnicalPapersButton}
					onButtonClick={allTechnicalPapersClickHandler}
					labelLatest={labelLatest}
				>
					<CardSummaryContainer>
						{technicalPapersItems.map((item, index) => {
							const key = `${item.title}-${index}`
							return (
								<TechnicalPapersItem
									key={key}
									buttonLabel={translations?.downloadPDFLabel}
									isLast={index === technicalPapersLength - 1}
									{...item}
								/>
							)
						})}
					</CardSummaryContainer>
				</CardSummary>
				<CardSummary
					title={webinarsTitle}
					description={webinarsDescription}
					buttonLabel={labelWebinarsButton}
					onButtonClick={allWebinarsClickHandler}
					labelLatest={labelLatest}
				>
					<CardSummaryContainer>
						{webinarItems.map((item, index) => {
							const key = `${item.title}-${index}`
							return (
								<WebinarItem
									key={key}
									durationUnit={labelMin}
									onClick={webinarItemClickHandler}
									isLast={index === webinarsLength - 1}
									hasBorder={false}
									{...item}
								/>
							)
						})}
					</CardSummaryContainer>
				</CardSummary>
			</Wrapper>
			<VideoModal
				transparent
				isOpen={isOpen}
				onClose={videoModalCloseHandler}
				videoId={videoId}
				title={title}
			/>
		</>
	)
}

export default KnowledgeBaseOverview
