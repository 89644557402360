/* eslint-disable react/require-default-props */
import { AssetFavouriteToggle } from '@components/AssetOverview/components/AssetFavouriteToggle'
import { AssetColumnKeys } from '@components/AssetOverview/types'
import { goToAssetDetail } from '@components/AssetOverviewFavourites/helpers'
import { SortingType } from '@components/GenericOverview/types'
import { TableItemTitle } from '@components/GenericOverview/styles'
import { SortIcon } from '@components/UI/SortIcon'
import { TableColumn } from '@components/Table/types'
import { GetAssetOverviewQuery } from '@graphql/graphql'
import { useRouter } from 'next/router'
import React from 'react'
import { StyledTable } from './styles'

interface AssetOverviewTableOrder {
	[column: string]: SortingType
}

interface AssetOverviewTableProps {
	items: GetAssetOverviewQuery['assets']['items']
	searchWord?: string
	orderBy?: AssetOverviewTableOrder
	onOrderByChange?: (orderBy: AssetOverviewTableOrder) => void
	nameColumnTitle: string
	yardNumberColumnTitle: string
	typeColumnTitle: string
	deliveryDateColumnTitle: string
	favouriteColumnTitle?: string
}

const AssetOverviewTable = ({
	items,
	searchWord,
	orderBy,
	onOrderByChange,
	nameColumnTitle,
	yardNumberColumnTitle,
	typeColumnTitle,
	deliveryDateColumnTitle,
	favouriteColumnTitle
}: AssetOverviewTableProps) => {
	const router = useRouter()

	const sortable = (column: TableColumn) => ({
		...column,
		sortIcon: onOrderByChange ? (
			<SortIcon
				direction={orderBy?.[column.field]}
				text={
					Array.isArray(column?.title) &&
					column?.title.every((item) => typeof item === 'string')
						? column.title.join(' / ')
						: column.title
				}
			/>
		) : null,
		onClick: onOrderByChange
			? () => {
					onOrderByChange({
						[column.field]:
							orderBy?.[column.field] === SortingType.asc
								? SortingType.desc
								: SortingType.asc
					})
			  }
			: null
	})

	return (
		<StyledTable
			head={{
				columns: [
					sortable({
						title: nameColumnTitle,
						field: AssetColumnKeys.vessel,
						width: '40%'
					}),
					sortable({
						title: yardNumberColumnTitle,
						field: AssetColumnKeys.yardNo,
						width: '20%'
					}),
					sortable({
						title: typeColumnTitle,
						field: AssetColumnKeys.type,
						width: '20%'
					}),
					sortable({
						title: deliveryDateColumnTitle,
						field: AssetColumnKeys.deliveryDateFormatted,
						width: '20%'
					}),
					{
						title: favouriteColumnTitle,
						field: AssetColumnKeys.favourite,
						width: '30px'
					}
				]
			}}
			body={{
				rows: items.map((item) => ({
					id: item.id,
					// eslint-disable-next-line no-void
					onClick: () => goToAssetDetail(router, item)(),
					columns: [
						{
							title: <TableItemTitle>{item.name}</TableItemTitle>,
							subline: '',
							searchWord
						},
						{
							title: item.yardNumber,
							subline: '',
							searchWord
						},
						{
							title: item?.vesselType?.id,
							subline: '',
							searchWord
						},
						{
							title: item.deliveryDateFormatted,
							subline: '',
							searchWord
						},
						{
							title: <AssetFavouriteToggle assetId={item.id} />,
							subline: '',
							searchWord
						}
					]
				}))
			}}
		/>
	)
}

export default AssetOverviewTable
